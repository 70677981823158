import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageHeader,
  BoxIntroDecor,
  BoxPoster,
  Poster,
  SectionHeader,
} from "../components/styles"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

export const query = graphql`
  query {
    poster: file(relativePath: { eq: "about/poster.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 488, maxHeight: 564) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const About = ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />
      <Grid container style={{ margin: "0 0 84px 0" }}>
        <Grid item xs={12} md={6}>
          <BoxIntroDecor>
            <PageHeader variant="h1">About</PageHeader>
            <Typography>
              I was born in Vietnam but arrived in Australia as a refugee with my brother Anh and Mum and Dad.
            </Typography>
            <Typography>
              I grew up in western Sydney and attended school at St Aloysius College in Milson’s Point. I studied arts at the University of Sydney where I developed a passion for storytelling after participating in theatresports and stand-up comedy at the Manning Bar.
            </Typography>
            <Typography>
              Since then I’ve worked in a variety of fields including film and television, keynote speaking, philanthropy, volunteering and the arts.
            </Typography>
            <Typography>
              I currently live in Adelaide with my family.
            </Typography>
          </BoxIntroDecor>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxPoster>
            <Poster fluid={data.poster.childImageSharp.fluid} />
          </BoxPoster>
        </Grid>
      </Grid>
      <Grid component="section" container>
        <Grid item xs={12}>
          <SectionHeader>For Students</SectionHeader>
          <Typography variant="h1">FAQs for Students</Typography>
        </Grid>
        <Grid item container>
          <Grid item xs={12} md={3} lg={2}>
            <Button
              component="a"
              href="faq.pdf"
              variant="outlined"
              size="large"
              download
              style={{ margin: "0 40px 40px 0", minWidth: "150px" }}
            >
              Download FAQ
            </Button>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Typography>
              I’m aware that our family’s story is being studied by a number of schools across the country and so I’m often asked by students to respond to questions regarding our story or my own personal experiences.
            </Typography>
            <Typography>
              Unfortunately I don’t have time to respond to all requests so I’ve collated a PDF download here with the questions I’m most often sent which I hope might help any student who may be interested.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default About
